@font-face {
  font-family: 'Roboto';
  src: local('Roboto'),
    url('../../../../public/fonts/Roboto-Regular.ttf') format('truetype');
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  height: 100%;
}

.react-datepicker-popper {
  z-index: 999999999 !important;
  @media (max-width: 450px) {
    top: 0% !important;
  }
}

.react-datepicker {
  font-family: 'Roboto', helvetica, arial, sans-serif;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__header {
  background-color: #fff;
  border: none;
  padding-top: 20px;
  padding-bottom: 0;
}

.react-datepicker__day-names {
  font-weight: 400;
  font-size: 12px;
  line-height: 166%;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.4px;
  color: #555555;
  margin-top: 12.5px;
}

.react-datepicker__month {
  margin-top: 12px;
  margin-left: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.17px;
  color: #2b2a2a;
}

.react-datepicker__week {
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-datepicker__day {
  background: #fff;
  width: 36px !important;
  height: 36px !important;
  margin: 4px;
  line-height: 36px !important;
}

.react-datepicker__day:hover,
.react-datepicker__day--in-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected {
  background: #00755a;
  border-radius: 18px;
  color: white;
}

.react-datepicker__day--selecting-range-start,
.react-datepicker__day--selecting-range-end,
.react-datepicker__day--in-selecting-range {
  background-color: #00755a !important;
  border-radius: 18px;
  color: white;
  transition: background-color 0.25s;
}

.react-datepicker__navigation {
  overflow: visible;
}

.react-datepicker__navigation-icon::before {
  border-color: #2b2a2a;
  border-style: solid;
  border-width: 2.2px 2.2px 0 0;
  content: '';
  display: block;
  height: 6px;
  position: absolute;
  top: 20px;
  width: 6px;
}

.react-datepicker__navigation--previous {
  left: 237px;
}

.react-datepicker__header {
  text-align: left;
}

.react-datepicker__current-month {
  margin-left: 24px;
  /* display: none; */
}

.react-datepicker__day-name {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 12px;
  line-height: 36px;
  width: 36px;
  height: 40px;
  margin: 4px;

  letter-spacing: 0.4px;
  gap: 0px;

  color: #555555;

  text-transform: uppercase;
}

.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__year-read-view--down-arrow {
  display: block;
  height: 5px;
  position: absolute;
  width: 5px;
  transform: rotate(0deg);
  border: none;
  top: 16px;
  right: -17px;
}

.react-datepicker__month-year-read-view--down-arrow::before,
.react-datepicker__year-read-view--down-arrow::before {
  content: '';
  width: 5px;
  height: 5px;
  background-color: #2b2a2a;
  position: absolute;
  transform: rotate(134deg);
  top: -5px;
  left: 2px;
  z-index: 1;
}

.react-datepicker__month-year-read-view--down-arrow::after,
.react-datepicker__year-read-view--down-arrow::after {
  content: '';
  width: 7px;
  height: 5px;
  background-color: white;
  position: absolute;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  top: -7.8px;
  left: 1px;
  z-index: 1;
}

.react-datepicker__month-year-read-view,
.react-datepicker__year-read-view {
  display: flex;
  align-self: center;
  visibility: visible !important;
}

.react-datepicker__header__dropdown {
  margin-top: 10px;
  margin-left: 24px;
}

.react-datepicker__year-dropdown-container--scroll {
  margin: 0;
}

.react-datepicker__month-year-dropdown,
.react-datepicker__year-dropdown {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  left: 0%;
  top: 50px;
  max-height: 150px;
  overflow-y: scroll;
  scrollbar-color: #b1b1b1 #ededed !important;
  scrollbar-width: thin !important;
}

.react-datepicker__month-year-dropdown::-webkit-scrollbar {
  height: 6px;
  width: 4px;
  background: #ededed;
  border-radius: 4px;
}

.react-datepicker__month-year-dropdown::-webkit-scrollbar-thumb {
  cursor: pointer;
  width: 4px;
  background: #b1b1b1;
  border-radius: 4px;
}

.react-datepicker__month-year-dropdown::-webkit-scrollbar-thumb:hover {
  background-color: #00755a;
}

.react-datepicker__month-year-read-view--selected-month-year,
.react-datepicker__year-read-view--selected-year {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  letter-spacing: 0.15px;

  /* Main text */

  color: #2b2a2a;
}

.react-datepicker__month-year-option,
.react-datepicker__month-year-option--selected_month-year,
.react-datepicker__year-option {
  padding: 5px 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.17px;
  color: #2b2a2a;
}

.react-datepicker__month-year-option--selected_month-year,
.react-datepicker__year-option--selected_year {
  background-color: #66ac9c;
}

.react-datepicker__month-year-option--selected,
.react-datepicker__year-option--selected {
  display: none;
}
