.rc-pagination {
  margin: 0;
  padding: 0;
  font-size: 14px;
}
.rc-pagination ul,
.rc-pagination ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.rc-pagination::after {
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  content: ' ';
}
.rc-pagination-total-text {
  display: inline-block;
  height: 28px;
  margin-right: 8px;
  line-height: 26px;
  vertical-align: middle;
}
.rc-pagination-item {
  display: inline-block;
  min-width: 28px;
  height: 28px;
  margin-right: 8px;
  line-height: 26px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: transparent;
  outline: 0;
  cursor: pointer;
  user-select: none;
}
.rc-pagination-item a {
  display: block;
  padding: 0 6px;
  color: rgba(0, 0, 0, 0.85);
  transition: none;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
}
.rc-pagination-item a:hover {
  text-decoration: none;
}
.rc-pagination-item:focus,
.rc-pagination-item:hover {
  border-color: #00755a;
  transition: all 0.3s;
}
.rc-pagination-item:focus a,
.rc-pagination-item:hover a {
  color: #00755a;
}
.rc-pagination-item-active {
  font-weight: 500;
  background: transparent;
  border-color: #00755a;
  cursor: default;
}
.rc-pagination-item-active a {
  color: #00755a;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
}
.rc-pagination-item-active:focus,
.rc-pagination-item-active:hover {
  border-color: #00755a;
}
.rc-pagination-item-active:focus a,
.rc-pagination-item-active:hover a {
  color: #00755a;
}
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
  outline: 0;
}
.rc-pagination-jump-prev button,
.rc-pagination-jump-next button {
  background: transparent;
  border: none;
  cursor: pointer;
  color: #666;
  vertical-align: text-top;
}
.rc-pagination-jump-prev button:after,
.rc-pagination-jump-next button:after {
  display: block;
  content: '...';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 2.6px;
  color: #414141;
}
.rc-pagination-prev,
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
  margin-right: 8px;
}
.rc-pagination-prev,
.rc-pagination-next,
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
  display: inline-block;
  min-width: 28px;
  height: 28px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}
.rc-pagination-prev,
.rc-pagination-next {
  outline: 0;
}
.rc-pagination-next button {
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  user-select: none;
  padding: 6px 17px;
}
.rc-pagination-prev button {
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  user-select: none;
  padding: 5px 17px;
}
.rc-pagination-prev:hover button,
.rc-pagination-next:hover button {
  border-color: #00755a;
  background-color: #e6f1ef;
}
.rc-pagination-prev .rc-pagination-item-link,
.rc-pagination-next .rc-pagination-item-link {
  width: 100%;
  height: 100%;
  font-size: 10px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  font-weight: 600;
  border: 1px solid #66ac9c;
  border-radius: 4px;
  color: #00755a;
  outline: none;
  transition: all 0.3s;
}
.rc-pagination-prev:focus .rc-pagination-item-link,
.rc-pagination-next:focus .rc-pagination-item-link,
.rc-pagination-prev:hover .rc-pagination-item-link,
.rc-pagination-next:hover .rc-pagination-item-link {
  color: transparent;
  border-color: #00755a;
}

.rc-pagination-prev button:after {
  content: url('/assets/icons/leftArrowIcon.svg');
  filter: invert(23%) sepia(60%) saturate(7368%) hue-rotate(163deg) brightness(88%)
    contrast(104%);
  width: 12px;
  height: 12px;
  display: block;
  margin: auto 0;
}
.rc-pagination-next button:after {
  content: url('/assets/icons/rightArrowIcon.svg');
  display: block;
  filter: invert(23%) sepia(60%) saturate(7368%) hue-rotate(163deg) brightness(88%)
    contrast(104%);
  width: 12px;
  height: 12px;
  display: block;
  margin: auto 0;
}
.rc-pagination-disabled,
.rc-pagination-disabled:hover,
.rc-pagination-disabled:focus {
  pointer-events: none;
}
.rc-pagination-disabled .rc-pagination-item-link,
.rc-pagination-disabled:hover .rc-pagination-item-link,
.rc-pagination-disabled:focus .rc-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  cursor: not-allowed;
  &::after {
    filter: brightness(0) saturate(100%) invert(31%) sepia(0%) saturate(0%)
      hue-rotate(218deg) brightness(99%) contrast(87%);
  }
}
.rc-pagination-slash {
  margin: 0 10px 0 5px;
}
.rc-pagination-options {
  display: inline-block;
  margin-left: 16px;
  vertical-align: middle;
}
@media all and (-ms-high-contrast: none) {
  .rc-pagination-options *::-ms-backdrop,
  .rc-pagination-options {
    vertical-align: top;
  }
}
.rc-pagination-options-size-changer.rc-select {
  display: inline-block;
  width: auto;
  margin-right: 8px;
}
.rc-pagination-options-quick-jumper {
  display: inline-block;
  height: 28px;
  line-height: 28px;
  vertical-align: top;
}
.rc-pagination-options-quick-jumper input {
  width: 50px;
  margin: 0 8px;
}
.rc-pagination-simple .rc-pagination-prev,
.rc-pagination-simple .rc-pagination-next {
  height: 24px;
  line-height: 24px;
  vertical-align: top;
}
.rc-pagination-simple .rc-pagination-prev .rc-pagination-item-link,
.rc-pagination-simple .rc-pagination-next .rc-pagination-item-link {
  height: 24px;
  background-color: transparent;
  border: 0;
}
.rc-pagination-simple .rc-pagination-prev .rc-pagination-item-link::after,
.rc-pagination-simple .rc-pagination-next .rc-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.rc-pagination-simple .rc-pagination-simple-pager {
  display: inline-block;
  height: 24px;
  margin-right: 8px;
}
.rc-pagination-simple .rc-pagination-simple-pager input {
  box-sizing: border-box;
  height: 100%;
  margin-right: 8px;
  padding: 0 6px;
  text-align: center;
  background-color: transparent;
  outline: none;
  transition: border-color 0.3s;
}
.rc-pagination-simple .rc-pagination-simple-pager input:hover {
  border-color: #00755a;
}
.rc-pagination.rc-pagination-disabled {
  cursor: not-allowed;
}
.rc-pagination.rc-pagination-disabled .rc-pagination-item {
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.rc-pagination.rc-pagination-disabled .rc-pagination-item a {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border: none;
  cursor: not-allowed;
}
.rc-pagination.rc-pagination-disabled .rc-pagination-item-active {
  background: #dbdbdb;
  border-color: transparent;
}
.rc-pagination.rc-pagination-disabled .rc-pagination-item-active a {
  color: transparent;
}
.rc-pagination.rc-pagination-disabled .rc-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.rc-pagination.rc-pagination-disabled .rc-pagination-item-link-icon {
  opacity: 0;
}
.rc-pagination.rc-pagination-disabled .rc-pagination-item-ellipsis {
  opacity: 1;
}
@media only screen and (max-width: 992px) {
  .rc-pagination-item-after-jump-prev,
  .rc-pagination-item-before-jump-next {
    display: none;
  }
}
@media only screen and (max-width: 576px) {
  .rc-pagination-options {
    display: none;
  }
  .rc-pagination-item a {
    margin-right: 0px;
  }
  .rc-pagination-prev button {
    padding: 7px;
  }
  .rc-pagination-next button {
    padding: 7px;
  }
}
